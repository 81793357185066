import * as React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "~/components/layout"
import HeroSection from "~/components/sections/news/hero"
import SEO from "~/components/seo"

const NewsPage: React.FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO title="News" />
    <HeroSection data={data} />
  </Layout>
)

export default NewsPage

export const query = graphql`
  query {
    allPrismicNews(sort: { fields: first_publication_date, order: DESC }) {
      edges {
        node {
          uid
          data {
            description {
              text
            }
            title {
              text
            }
            cover {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`
