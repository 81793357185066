import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"

import Container from "~/components/ui/container"
import Button from "~/components/ui/button"

import * as styles from "./index.module.scss"

const NewsPiece = ({ imgData, headline, description, link }) => {
  return (
    <div className={styles.item}>
      <div className={styles.item__img}>
        <GatsbyImage
          alt={imgData.alt || ""}
          image={imgData.localFile.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="50% 50%"
        />
      </div>
      <div className={styles.item__info}>
        <div>
          <h2 className={styles.item__headline}>{headline}</h2>
          <p className={styles.item__paragraph}>{description}</p>
        </div>
        <div className={styles.item__button}>
          <Button isLink={true} to={link}>
            Read More
          </Button>
        </div>
      </div>
    </div>
  )
}

interface Props {
  data: any
}

const HeroSection: React.FC<Props> = ({ data }) => {
  const [isAnimated, setIsAnimated] = useState(false)
  useEffect(() => {
    setIsAnimated(true)
  }, [setIsAnimated])
  return (
    <section className={styles.root}>
      <div
        className={classNames(styles.animation, {
          [styles.animation__animated]: isAnimated,
        })}
      ></div>
      <Container>
        <h1 className={classNames("headline--decorated", styles.headline)}>
          Urus <span>News</span>
        </h1>
        <div className={styles.items}>
          {data.allPrismicNews.edges.map(({ node: { uid, data } }) => {
            return (
              <NewsPiece
                key={uid}
                imgData={data.cover}
                headline={data.title.text}
                description={data.description.text}
                link={`/news/${uid}`}
              />
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default HeroSection
