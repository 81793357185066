// extracted by mini-css-extract-plugin
export var animation = "index-module--animation--4c0ac";
export var animation__animated = "index-module--animation__animated--9af27";
export var headline = "index-module--headline--fbfbe";
export var item = "index-module--item--b0744";
export var item__button = "index-module--item__button--efffb";
export var item__headline = "index-module--item__headline--e89f0";
export var item__img = "index-module--item__img--90bd5";
export var item__info = "index-module--item__info--f408e";
export var item__paragraph = "index-module--item__paragraph--21a65";
export var items = "index-module--items--8c135";
export var root = "index-module--root--d0da1";